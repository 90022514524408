import {
  OrganizationSwitcher,
  UserButton,
  useOrganizationList,
} from '@clerk/clerk-react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, CssBaseline, IconButton, Toolbar } from '@mui/material'

import { routes, useLocation } from '@redwoodjs/router'
import { Toaster } from '@redwoodjs/web/toast'

import Toast from 'src/components/Toast'
import useAppLayoutState from 'src/hooks/useAppLayoutSlice'
import useHistoryCount from 'src/hooks/useHistoryCount'
import useUploadEventListeners from 'src/hooks/useUploadEventListeners'

import Drawer from './Drawer'
import NotificationPopover from './NotificationPopover'

type AppLayoutProps = {
  children?: React.ReactNode
}

const AppLayout = ({ children }: AppLayoutProps) => {
  const { organizationList, isLoaded } = useOrganizationList()
  const { navDrawerExpanded, setNavDrawerExpanded } = useAppLayoutState()
  const showOrganizationSwitcher = React.useMemo(
    () => isLoaded && organizationList.length > 1,
    [isLoaded, organizationList]
  )
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const isDocHubPreview = queryParams.get('view') === 'docHubPreview'

  const handleDrawerOpen = () => {
    setNavDrawerExpanded(!navDrawerExpanded)
  }

  useHistoryCount()
  useUploadEventListeners()

  return (
    <div className="flex">
      <CssBaseline />
      {!isDocHubPreview && (
        <>
          <AppBar position="fixed" color="white" elevation={1}>
            <Toolbar>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
              >
                <MenuIcon />
              </IconButton>

              <img src="/WingWork.png" alt="Wingwork Logo" width={'200px'} />

              <div className="flex-grow" />

              <div className="flex items-center gap-1">
                <IconButton size="large" aria-label="help area" color="inherit">
                  <HelpOutlineIcon />
                </IconButton>
                <NotificationPopover />
                {showOrganizationSwitcher && (
                  <OrganizationSwitcher
                    hidePersonal
                    afterSelectOrganizationUrl={routes.landing()}
                  />
                )}
                <UserButton showName />
              </div>
            </Toolbar>
          </AppBar>
          <Drawer expanded={navDrawerExpanded} />
        </>
      )}
      <main className="flex-grow">
        {!isDocHubPreview && <Toolbar />}
        {children}
      </main>
      <Toaster position="top-right" containerClassName="mt-7 z-51">
        {(t) => <Toast t={t} message={t.message} />}
      </Toaster>
    </div>
  )
}

export default AppLayout
