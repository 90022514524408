
import { createSlice } from '@reduxjs/toolkit'

interface State {
  data: {
    showChildItems: boolean
  }
}

const initialState: State = {
  data: {
    showChildItems: false,
  },
}

const duelistLocalFiltersSlice = createSlice({
  name: 'duelistLocalFilters',
  initialState,
  reducers: {
    setDuelistLocalFiltersData: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setDuelistLocalFiltersData } = duelistLocalFiltersSlice.actions

export default duelistLocalFiltersSlice.reducer
