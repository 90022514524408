import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MaintenanceItem } from 'types/graphql'

import { isMaintenanceItemInProgress } from 'src/utils/maintenanceItem'

//Type for selected in progress
type SelectedInProgressItem = {
  ataCode: string
  manufactureCode: string
  title: string
}

type SelectedInProgress = {
  // Key should be the id of the maintenance item
  [key: string]: SelectedInProgressItem
}

// Type for Modal state
interface ModalState {
  selected: string[]
  selectedInProgress: SelectedInProgress
}

// Initial state
const initialState: ModalState = {
  selected: [],
  selectedInProgress: {},
}

const dueListSlice = createSlice({
  name: 'dueList',
  initialState,
  reducers: {
    select(state, action: PayloadAction<MaintenanceItem | MaintenanceItem[]>) {
      const maintenanceItems = Array.isArray(action.payload)
        ? action.payload
        : [action.payload]
      const ids = maintenanceItems.map((item) => item.id)

      const uniqueIds = new Set([...state.selected, ...ids])
      state.selected = Array.from(uniqueIds)

      maintenanceItems.forEach((item) => {
        if (isMaintenanceItemInProgress(item)) {
          state.selectedInProgress[item.id] = {
            ataCode: item.ataCode,
            manufactureCode: item.manufactureCode,
            title: item.title,
          }
        }
      })
    },
    unselect(
      state,
      action: PayloadAction<MaintenanceItem | MaintenanceItem[]>
    ) {
      const ids = Array.isArray(action.payload)
        ? action.payload.map((item) => item.id)
        : [action.payload.id]
      state.selected = state.selected.filter((id) => !ids.includes(id))

      ids.forEach((id) => {
        delete state.selectedInProgress[id]
      })
    },
    unselectAll(state) {
      state.selected = []
      state.selectedInProgress = {}
    },
  },
})

export type { SelectedInProgressItem, SelectedInProgress }

export const { select, unselect, unselectAll } = dueListSlice.actions

export default dueListSlice.reducer
