import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import {
  DueListPrevDeps,
  DueListTableFiltersData,
  DuelistFilterProjection,
} from 'src/types'
import { isNotEmpty } from 'src/utils/helpers'

import { reset } from '../sharedActions'

export const initialProjectionFilterData: DuelistFilterProjection = {
  // date: dayjs().format('MM/DD/YYYY'),
  date: undefined,
  isOptional: undefined,
  isPartBased: undefined,
  statuses: undefined,
  cadenceType: undefined,
  projectionGrid: [
    {
      id: 1,
      metric: 'Landings',
      dailyUse: 1,
      days: 1,
      override: false,
      projection: 1,
    },
    {
      id: 2,
      metric: 'Hours',
      dailyUse: 1,
      days: 1,
      override: false,
      projection: 1,
    },
    {
      id: 3,
      metric: 'Cycles',
      dailyUse: 1,
      days: 1,
      override: false,
      projection: 1,
    },
    {
      id: 4,
      metric: 'APU Hours',
      dailyUse: 1,
      days: 1,
      override: false,
      projection: 1,
    },
  ],
}

export const initialFilterData: DueListTableFiltersData = {
  fuzzySearchTerms: '',
  powerSearchTerms: {},
  aircrafts: {},
  dueStatus: {
    overdue: false,
    inTolerance: false,
    notInDue: false,
  },
  interval: {
    flyingHours: false,
    day: false,
    month: false,
    landing: false,
    takeoff: false,
  },
  assignedMechanic: {},
  inspectionDate: dayjs().toString(),
  timeFrameAll: false,
  timeframeDays: {
    option: '',
    days: -1,
  },
  timeframeHours: {
    hours: -1,
    option: '',
  },
  timeframeCycles: {
    cycles: -1,
    option: '',
  },
  timeframeLandings: {
    landings: -1,
    option: '',
  },
  filterDataBy: [],
  projectionFilterData: initialProjectionFilterData,
  tableRefreshKey: 0,
}

export const initialDuelistPrevDeps: DueListPrevDeps = {
  fuzzySearchTerms: '',
  powerSearchTerms: {},
  aircrafts: {},
  timeFrameAll: false,
  timeframeDays: { option: '' },
  timeframeHours: { option: '' },
  timeframeCycles: { option: '' },
  timeframeLandings: { option: '' },
  filterDataBy: [],
  projectionFilterData: initialProjectionFilterData,
}
export interface DueListSearchParams {
  activeTimeframeFilter?: string
  fuzzySearchTerms?: any
  powerSearchTerms?: any
  timeFrameAll?: boolean
  filterDataBy?: string
  aircrafts?: string
  option?: string
  days?: number
  landings?: number
  cycles?: number
  hours?: number
}

export function getDuelistSearchParams(
  filtersData: DueListTableFiltersData
): DueListSearchParams {
  const {
    filterDataBy,
    fuzzySearchTerms,
    powerSearchTerms,
    aircrafts: aircraftFilter,
    timeFrameAll,
    timeframeCycles,
    timeframeLandings,
    timeframeDays,
    timeframeHours,
  } = filtersData
  const selectedAircrafts = Object.keys(aircraftFilter).filter(
    (acId) => aircraftFilter[acId]
  )
  let activeTimeframeFilter = ''
  if (timeframeDays.days > 0) {
    activeTimeframeFilter = 'days'
  } else if (timeframeHours.hours > 0) {
    activeTimeframeFilter = 'hours'
  } else if (timeframeCycles.cycles > 0) {
    activeTimeframeFilter = 'cycles'
  } else if (timeframeLandings.landings > 0) {
    activeTimeframeFilter = 'landings'
  }
  const timeframe = {
    ...(timeframeDays.days > 0 && timeframeDays),
    ...(timeframeHours.hours > 0 && timeframeHours),
    ...(timeframeCycles.cycles > 0 && timeframeCycles),
    ...(timeframeLandings.landings > 0 && timeframeLandings),
  }

  const getPowerSearchUrlParams = () => {
    const searchTermsKeys = Object.keys(powerSearchTerms)
    return searchTermsKeys.reduce((acc, key) => {
      const searchTerm = powerSearchTerms[key]
      if (searchTerm) {
        return {
          ...acc,
          [`powerSearch${key}`]: searchTerm,
        }
      }
      return acc
    }, {})
  }

  return {
    ...timeframe,
    ...(activeTimeframeFilter && { activeTimeframeFilter }),
    ...(fuzzySearchTerms && { fuzzySearchTerms: fuzzySearchTerms }),
    ...(Object.keys(powerSearchTerms).length > 0 && {
      ...getPowerSearchUrlParams(),
    }),
    ...(timeFrameAll && { timeFrameAll }),
    ...(filterDataBy.length > 0 && {
      filterDataBy: filterDataBy.join(','),
    }),
    ...(selectedAircrafts.length > 0 && {
      aircrafts: selectedAircrafts.join(','),
    }),
  }
}

export function areDuelistPrevDepValuesReset(
  prevDeps: DueListPrevDeps
): boolean {
  const {
    powerSearchTerms: prevPowerSearchTerm,
    fuzzySearchTerms: prevFuzzySearchTerm,
    aircrafts: prevAircrafts,
    timeFrameAll: prevTimeFrameAll,
    timeframeDays: prevTimeframeDays,
    timeframeHours: prevTimeframeHours,
    timeframeCycles: prevTimeframeCycles,
    timeframeLandings: prevTimeframeLandings,
    filterDataBy: prevFilterDataBy,
  } = prevDeps

  return (
    isNotEmpty(prevFuzzySearchTerm) ||
    isNotEmpty(prevPowerSearchTerm) ||
    isNotEmpty(prevAircrafts) ||
    isNotEmpty(prevTimeFrameAll) ||
    isNotEmpty(prevTimeframeDays?.option) ||
    isNotEmpty(prevTimeframeHours?.option) ||
    isNotEmpty(prevTimeframeCycles?.option) ||
    isNotEmpty(prevTimeframeLandings?.option) ||
    prevFilterDataBy.length !== 0
  )
}

export function updateDuelistPrevDeps(
  filtersData: DueListTableFiltersData
): DueListPrevDeps {
  return {
    fuzzySearchTerms: filtersData.fuzzySearchTerms,
    powerSearchTerms: filtersData.powerSearchTerms,
    aircrafts: filtersData.aircrafts,
    timeFrameAll: filtersData.timeFrameAll,
    timeframeDays: filtersData.timeframeDays,
    timeframeHours: filtersData.timeframeHours,
    timeframeCycles: filtersData.timeframeCycles,
    timeframeLandings: filtersData.timeframeLandings,
    filterDataBy: filtersData.filterDataBy,
    projectionFilterData: filtersData.projectionFilterData,
  }
}

export function createDuelistFiltersObject(
  searchParams: URLSearchParams
): DueListTableFiltersData {
  const getParam = (key) => searchParams.get(key)
  const getParamArray = (key) => getParam(key)?.split(',') || []
  const getParamBool = (key) => getParam(key) === 'true'
  const getParamNumber = (key) => Number(getParam(key)) || -1

  const getPowerSearchTerms = () => {
    const powerSearchTermKeys = []
    for (const key of searchParams.keys()) {
      if (key.startsWith('powerSearch')) {
        powerSearchTermKeys.push(key)
      }
    }

    if (!powerSearchTermKeys.length) return null
    return powerSearchTermKeys.reduce((acc, key) => {
      const searchTerm = searchParams.get(key)
      const searchTermKey = key.replace('powerSearch', '')
      return {
        ...acc,
        [searchTermKey]: searchTerm,
      }
    }, {})
  }

  const filterDataBy = getParam('filterDataBy')
    ? getParamArray('filterDataBy')
    : initialFilterData.filterDataBy
  const powerSearchTerms =
    getPowerSearchTerms() || initialFilterData.powerSearchTerms
  const fuzzySearchTerms =
    getParam('fuzzySearchTerms') || initialFilterData.fuzzySearchTerms

  const aircrafts = getParam('aircrafts')
    ? Object.fromEntries(getParamArray('aircrafts').map((acId) => [acId, true]))
    : initialFilterData.aircrafts
  const timeFrameAll =
    getParamBool('timeFrameAll') || initialFilterData.timeFrameAll

  const activeTimeframeFilter = getParam('activeTimeframeFilter')
  const timeOption = getParam('option')
  const timeframeDays =
    activeTimeframeFilter === 'days'
      ? { days: getParamNumber('days'), option: timeOption }
      : initialFilterData.timeframeDays
  const timeframeHours =
    activeTimeframeFilter === 'hours'
      ? { hours: getParamNumber('hours'), option: timeOption }
      : initialFilterData.timeframeHours
  const timeframeCycles =
    activeTimeframeFilter === 'cycles'
      ? { cycles: getParamNumber('cycles'), option: timeOption }
      : initialFilterData.timeframeCycles
  const timeframeLandings =
    activeTimeframeFilter === 'landings'
      ? { landings: getParamNumber('landings'), option: timeOption }
      : initialFilterData.timeframeLandings

  return {
    ...initialFilterData,
    filterDataBy,
    powerSearchTerms,
    fuzzySearchTerms,
    aircrafts,
    timeFrameAll,
    timeframeDays,
    timeframeHours,
    timeframeCycles,
    timeframeLandings,
    projectionFilterData: initialProjectionFilterData,
  }
}

interface DuelistFilterSliceState {
  data: DueListTableFiltersData
}

const initialState: DuelistFilterSliceState = {
  data: initialFilterData,
}

const duelistFiltersSlice = createSlice({
  name: 'duelistFilters',
  initialState,
  reducers: {
    setDuelistFiltersData(
      state,
      action: PayloadAction<DueListTableFiltersData>
    ): void {
      state.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState)
  },
})

export const { setDuelistFiltersData } = duelistFiltersSlice.actions

export default duelistFiltersSlice.reducer
