import { useEffect, useState } from 'react'

/**
 * React Hook to determine if the document is currently visible or not.
 * It listens to the 'visibilitychange' event on the document and updates the visibility state accordingly.
 * @returns {boolean} - Returns true if the document is visible, false otherwise.
 */
const useIsVisible = (): boolean => {
  const [isVisible, setIsVisible] = useState<boolean>(
    document.visibilityState === 'visible'
  )

  useEffect(() => {
    const handleVisibilityChange = (): void => {
      setIsVisible(document.visibilityState === 'visible')
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return (): void => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return isVisible
}

export default useIsVisible
