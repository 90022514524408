import { useEffect, useState } from 'react'

/**
 * `useExponentialBackoff` is a custom React hook that provides an exponential backoff mechanism.
 * It starts with an initial interval and doubles it after each interval until it reaches a maximum interval.
 * Optionally, it can reset the interval to the initial value on any user action (click or keydown).
 *
 * @param {number} initialInterval - The initial interval in milliseconds.
 * @param {number} maxInterval - The maximum interval in milliseconds. The backoff will not increase beyond this value.
 * @param {boolean} [resetOnAction=false] - If true, the interval will reset to the initial value on any user action.
 *
 * @returns {number} The current interval in milliseconds.
 *
 * @example
 * const interval = useExponentialBackoff(10000, 60000, true);
 */
const useExponentialBackoff = (
  initialInterval: number,
  maxInterval: number,
  resetOnAction = false
): number => {
  const [interval, setInterval] = useState<number>(initialInterval)

  useEffect(() => {
    const increaseInterval = () => {
      setInterval((currentInterval: number) =>
        Math.min(currentInterval * 2, maxInterval)
      )
    }

    const intervalId = window.setInterval(increaseInterval, interval)

    return () => window.clearInterval(intervalId)
  }, [interval, maxInterval])

  useEffect(() => {
    if (resetOnAction) {
      const resetInterval = () => {
        setInterval(initialInterval)
      }

      window.addEventListener('click', resetInterval)
      window.addEventListener('keydown', resetInterval)

      return () => {
        window.removeEventListener('click', resetInterval)
        window.removeEventListener('keydown', resetInterval)
      }
    }
  }, [initialInterval, resetOnAction])

  return interval
}

export default useExponentialBackoff
