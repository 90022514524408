import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import ClearAllRoundedIcon from '@mui/icons-material/ClearAllRounded'
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined'
import FlightTakeoffOutlinedIcon from '@mui/icons-material/FlightTakeoffOutlined'
import HistoryIcon from '@mui/icons-material/History'
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined'
import PeopleOutlineOutlined from '@mui/icons-material/PeopleOutlineOutlined'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import PurchaseOrderIcon from '@mui/icons-material/RequestPageOutlined'
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded'
import SettingsIcon from '@mui/icons-material/Settings'
import SnippetFolderOutlinedIcon from '@mui/icons-material/SnippetFolderOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Drawer as MUIDrawer,
  Toolbar,
  Tooltip,
} from '@mui/material'
import clsx from 'clsx'

import { Link, routes, useLocation, useParams } from '@redwoodjs/router'

type ListItem = {
  text: string
  icon: React.ReactElement
  to?: string
}

const ListGroup = ({
  title,
  items,
  expanded,
}: {
  title?: string
  items: ListItem[]
  expanded: boolean
}) => (
  <List
    subheader={
      title && (
        <ListSubheader
          className={`h-6 normal-case ${
            expanded ? 'opacity-100' : 'opacity-0'
          }`}
          sx={{ p: ['40px', '16px', '16px', '16px'] }}
        >
          {title}
        </ListSubheader>
      )
    }
  >
    {items.map(({ text, icon, to }) => (
      <ListItem key={text} disablePadding>
        <Tooltip
          title={text}
          placement="right"
          slotProps={{
            // offset used to snug the tooltip to the button just right
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -7],
                  },
                },
              ],
            },
          }}
        >
          <ListItemButton
            disabled={!to}
            component={to && Link}
            to={to && to}
            className={`min-h-6 px-[15px] ${
              expanded ? 'justify-[initial]' : 'justify-center'
            }`}
          >
            <ListItemIcon
              className={`min-w-0 justify-center ${expanded ? 'mr-3' : 'mr-0'}`}
            >
              {icon}
            </ListItemIcon>
            <ListItemText
              primary={text}
              className={expanded ? 'opacity-100' : 'opacity-0'}
            />
          </ListItemButton>
        </Tooltip>
      </ListItem>
    ))}
  </List>
)

const INTERNAL_PATH_NAMES = ['/admin', '/onboarding']

const Drawer = ({ expanded = true }: { expanded?: boolean }) => {
  const { pathname } = useLocation()
  const pathParams = useParams()

  const isAdminPage = React.useMemo(
    () => INTERNAL_PATH_NAMES.some((path) => pathname.startsWith(path)),
    [pathname]
  )
  const orgName = !isAdminPage ? pathParams.orgName : undefined
  const sidebarNavigationGroups = React.useMemo(() => {
    if (isAdminPage) {
      return {
        Manage: [
          {
            text: 'Users',
            icon: <PeopleOutlineOutlined />,
            to: routes.administration({ component: 'users' }),
          },
          {
            text: 'Organizations',
            icon: <CorporateFareOutlinedIcon />,
            to: routes.administration({ component: 'organizations' }),
          },
          {
            text: 'Aircraft',
            icon: <FlightTakeoffOutlinedIcon />,
            to: routes.administration({ component: 'aircrafts' }),
          },
          {
            text: 'Activity',
            icon: <QueryStatsIcon />,
            to: routes.administration({ component: 'activity' }),
          },
        ],
      }
    } else {
      return {
        Analytics: [
          {
            text: 'Dashboard',
            icon: <DashboardOutlinedIcon />,
            to: routes.fleetDashboard({ orgName: orgName }),
          },
          {
            text: 'Reports',
            icon: <AutoGraphOutlinedIcon />,
            to: routes.reports({ orgName: orgName }),
          },
          {
            text: 'Document Hub',
            icon: <SnippetFolderOutlinedIcon />,
            to: routes.documentHub({ orgName: orgName }),
          },
        ],
        Maintenance: [
          {
            text: 'Due List',
            icon: <ClearAllRoundedIcon />,
            to: routes.dueList({ orgName: orgName }),
          },
          {
            text: 'Past Compliance',
            icon: <AssignmentOutlinedIcon />,
            to: routes.workCompleted({ orgName: orgName }),
          },
          {
            text: 'Logbook Entries',
            icon: <WorkOutlineOutlinedIcon />,
            to: routes.logbookEntriesLanding({ orgName: orgName }),
          },
          {
            text: 'Activity Log',
            icon: <HistoryIcon />,
            to: routes.activityLog({ orgName: orgName }),
          },
          {
            text: 'Purchase Orders',
            icon: <PurchaseOrderIcon />,
            to: routes.purchaseOrders({ orgName: orgName }),
          },
          { text: 'Inventory', icon: <InboxOutlinedIcon /> },
        ],
        'Work Orders': [
          {
            text: 'My Work Orders',
            icon: <EngineeringOutlinedIcon />,
            to: routes.workOrders({
              orgName: orgName,
              ordersDiscriminator: 'myorders',
            }),
          },
          {
            text: 'Scheduled',
            icon: <ScheduleRoundedIcon />,
            to: routes.workOrders({
              orgName: orgName,
              ordersDiscriminator: 'scheduled',
            }),
          },

          {
            text: 'Completed',
            icon: <CheckRoundedIcon />,
            to: routes.workOrders({
              orgName: orgName,
              ordersDiscriminator: 'completed',
            }),
          },
        ],
      }
    }
  }, [pathParams.orgName, pathname])

  const expandedDrawerClasses =
    'transition-all ease-in duration-300 overflow-x-hidden w-30'
  const miniDrawerClasses =
    'transition-all ease-out duration-300 overflow-x-hidden w-7'

  return (
    <MUIDrawer
      variant="permanent"
      anchor={'left'}
      sx={{ zIndex: (theme) => theme.zIndex.appBar - 1 }}
      className={clsx({
        ['box-sizing-border w-30 flex-shrink-0 whitespace-nowrap']: true,
        [expandedDrawerClasses]: expanded,
        [miniDrawerClasses]: !expanded,
      })}
      classes={{
        paper: clsx({
          [expandedDrawerClasses]: expanded,
          [miniDrawerClasses]: !expanded,
        }),
      }}
    >
      <Toolbar />

      {Object.keys(sidebarNavigationGroups).map((key) => {
        const items = sidebarNavigationGroups[key]
        return (
          <ListGroup key={key} title={key} items={items} expanded={expanded} />
        )
      })}

      <div style={{ flexGrow: 1 }} />
      <List>
        <Divider />
        {!isAdminPage && (
          <ListItem disablePadding className="block">
            <ListItemButton
              className={`min-h-6 px-[15px] ${
                expanded ? 'justify-[initial]' : 'justify-center'
              }`}
              component={Link}
              to={routes.settings({ orgName })}
            >
              <ListItemIcon
                className={`min-w-0 justify-center ${
                  expanded ? 'mr-3' : 'mr-auto'
                }`}
              >
                <SettingsIcon />
              </ListItemIcon>

              <ListItemText
                primary="Settings"
                className={expanded ? 'opacity-100' : 'opacity-0'}
              />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </MUIDrawer>
  )
}

export default Drawer
