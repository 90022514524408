export const Permissions = {
  compliance: {
    create: 'org:compliance:create' as const,
    read: 'org:compliance:read' as const,
    update: 'org:compliance:update' as const,
    complete: 'org:compliance:complete' as const,
    actAsTechnician: 'org:compliance:act_as_technician' as const,
    canActAsInspector: 'org:compliance:can_act_as_inspector' as const,
  },
  maintenanceItem: {
    create: 'org:maintenance_item:create' as const,
    read: 'org:maintenance_item:read' as const,
    update: 'org:maintenance_item:update' as const,
    delete: 'org:maintenance_item:delete' as const,
    modifyInterval: 'org:maintenance_item:modify_interval' as const,
    modifyNextDueValue: 'org:maintenance_item:modify_next_due_value' as const,
    modifyRate: 'org:maintenance_item:modify_rate' as const,
    modifyWorkHours: 'org:maintenance_item:modify_work_hours' as const,
  },
  user: {
    add: 'org:user:add' as const,
    grantPermission: 'org:user:grant_permission' as const,
    grantRole: 'org:user:grant_role' as const,
    remove: 'org:user:remove' as const,
    revokePermission: 'org:user:revoke_permission' as const,
    revokeRole: 'org:user:revoke_role' as const,
  },
  workOrder: {
    create: 'org:work_order:create' as const,
    read: 'org:work_order:read' as const,
    update: 'org:work_order:update' as const,
    performWorkItem: 'org:work_order:perform_work_item' as const,
    addTasks: 'org:work_order:add_tasks' as const,
    removeTasks: 'org:work_order:remove_tasks' as const,
    complete: 'org:work_order:complete' as const,
    actAsInspector: 'org:work_order:act_as_inspector' as const,
    actAsTechnician: 'org:work_order:act_as_technician' as const,
  },
  purchaseOrder: {
    create: 'org:purchase_order:create' as const,
    addTasks: 'org:purchase_order:add_tasks' as const,
  },
}

type CompliancePermission =
  (typeof Permissions.compliance)[keyof typeof Permissions.compliance]

type MaintenanceItemPermission =
  (typeof Permissions.maintenanceItem)[keyof typeof Permissions.maintenanceItem]

type UserPermission = (typeof Permissions.user)[keyof typeof Permissions.user]

type WorkOrderPermission =
  (typeof Permissions.workOrder)[keyof typeof Permissions.workOrder]

type PurchaseOrderPermission =
  (typeof Permissions.purchaseOrder)[keyof typeof Permissions.purchaseOrder]

export type OrgPermissions =
  | CompliancePermission
  | MaintenanceItemPermission
  | UserPermission
  | WorkOrderPermission
  | PurchaseOrderPermission
