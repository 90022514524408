import CircleIcon from '@mui/icons-material/Circle'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import LoadingButton from '@mui/lab/LoadingButton'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import clsx from 'clsx'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'

import { navigate } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

dayjs.extend(relativeTime)

const MARK_NOTIFICATION_AS_READ = gql`
  mutation markReadStatus($id: String!, $read: Boolean) {
    updateNotification(id: $id, input: { read: $read }) {
      id
      read
    }
  }
`

const PERFORM_NOTIFICATION_ACTION = gql`
  mutation PerformNotificationAction($id: String!) {
    performNotificationAction(id: $id) {
      success
      notification {
        id
        read
        actionPerformed
      }
    }
  }
`

const Notification = ({ notification, handleClose }) => {
  const { t } = useTranslation()
  const [showMore, setShowMore] = React.useState(false)
  const [showMoreEl, setShowMoreEl] = React.useState<HTMLButtonElement | null>(
    null
  )
  const [markReadStatus] = useMutation(MARK_NOTIFICATION_AS_READ)
  const [performAction, { loading: performActionLoading }] = useMutation(
    PERFORM_NOTIFICATION_ACTION
  )

  const handleClick = () => {
    if (!notification.read) {
      markReadStatus({ variables: { id: notification.id, read: true } })
    }

    if (notification.link) {
      navigate(notification.link)
      handleClose()
    }
  }

  const handleActionClick = async (e) => {
    e.stopPropagation()
    performAction({ variables: { id: notification.id } })
  }

  const handleMoreClick = (e) => {
    e.stopPropagation()
    setShowMoreEl(e.currentTarget)
  }

  const handleMoreClose = (e) => {
    e.stopPropagation()
    setShowMoreEl(null)
  }

  return (
    <>
      {/* eslint-disable-next-line */}
      <div
        key={notification.id}
        className="flex w-full cursor-pointer flex-col justify-between rounded-md p-1 transition-colors duration-200 ease-in-out hover:bg-gray-100"
        onClick={handleClick}
        onMouseEnter={() => setShowMore(true)}
        onMouseLeave={() => setShowMore(false)}
      >
        <div className="flex items-center justify-between gap-2">
          <div className="flex flex-col">
            <Markdown components={{ p: 'span' }} className="line-clamp-3">
              {notification.message.replace(/\n/g, '  \n')}
            </Markdown>
            <Typography
              variant="subtitle2"
              className={notification.read ? 'text-gray-400' : 'text-blue-400'}
            >
              {dayjs(notification.createdAt).fromNow()}
            </Typography>
          </div>

          <div className="flex items-center gap-1">
            <IconButton
              size="small"
              className={clsx({
                'opacity-0': true,
                'opacity-100': showMore || !!showMoreEl,
              })}
              onClick={handleMoreClick}
            >
              <MoreHorizIcon fontSize="small" />
            </IconButton>
            <CircleIcon
              fontSize="small"
              className={clsx({
                'text-blue-500 transition-colors duration-100 ease-in-out':
                  true,
                'opacity-0': notification.read,
              })}
            />
          </div>
        </div>
        {notification.actionName && (
          <LoadingButton
            fullWidth
            onClick={handleActionClick}
            disabled={notification.actionPerformed}
            loading={performActionLoading}
            loadingPosition="end"
          >
            {t(`notification.actionTitle.${notification.actionName}`, {
              defaultValue: notification.actionName,
            })}
          </LoadingButton>
        )}
      </div>
      <Menu
        id="show-more-menu"
        anchorEl={showMoreEl}
        open={!!showMoreEl}
        onClose={handleMoreClose}
      >
        {!notification.read && (
          <MenuItem
            onClick={(e) => {
              handleMoreClose(e)
              markReadStatus({
                variables: { id: notification.id, read: true },
              })
            }}
          >
            Mark Read
          </MenuItem>
        )}
        {notification.read && (
          <MenuItem
            onClick={(e) => {
              handleMoreClose(e)
              markReadStatus({
                variables: { id: notification.id, read: false },
              })
            }}
          >
            Mark Unread
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default Notification
