import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppLayoutState } from 'src/types'

const drawerExpanded = localStorage.getItem('drawerExpanded')

const initialState: AppLayoutState = {
  navDrawerExpanded: drawerExpanded === null ? true : drawerExpanded === 'true',
}

const appLyoutSlice = createSlice({
  name: 'appLayout',
  initialState,
  reducers: {
    setNavDrawerExpanded: (
      state: AppLayoutState,
      action: PayloadAction<boolean>
    ) => {
      state.navDrawerExpanded = action.payload
      localStorage.setItem('drawerExpanded', `${action.payload}`)
    },
  },
})

export const { setNavDrawerExpanded } = appLyoutSlice.actions

export default appLyoutSlice.reducer
