import useQuery from './useQuery'

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
    }
  }
`

const useUserId = () => {
  const { data, hasLoaded } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'cache-first',
  })

  return { id: data?.currentUser?.id, hasLoaded }
}

export default useUserId
