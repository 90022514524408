import { useCallback, useEffect } from 'react'

import {
  useAuth as useClerkAuth,
  useOrganization,
  useOrganizationList,
  useSession,
} from '@clerk/clerk-react'
import Intercom from '@intercom/messenger-js-sdk'
import OneSignal from 'react-onesignal'

import { Redirect, routes } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import { useDispatch } from 'src/hooks/useDispatch'
import { useOrgName } from 'src/hooks/useOrgName'
import useUserId from 'src/hooks/useUserId'
import datadogRum from 'src/lib/datadogRum'
import Sentry from 'src/lib/sentry'
import { reset } from 'src/sharedActions'
import { clearClerkData, setClerkData } from 'src/slices/clerk'

type AuthenticatorLayoutProps = {
  children?: React.ReactNode
}

const AuthenticatorLayout = ({ children }: AuthenticatorLayoutProps) => {
  const dispatch = useDispatch()
  const { loading: isAuthLoading, isAuthenticated, currentUser } = useAuth()
  const { session } = useSession()
  const [switchOrgInProgress, setSwitchOrgInProgress] =
    React.useState<boolean>(false)
  const {
    isLoaded: isOrgLoaded,
    organizationList,
    setActive,
  } = useOrganizationList()
  const { orgSlug: activeOrgSlug, isLoaded: isClerkAuthLoaded } = useClerkAuth()
  const urlOrgSlug = useOrgName()
  const unauthenticated = useCallback(() => !isAuthenticated, [isAuthenticated])
  const [oneSignalInitialized, setOneSignalInitialized] = React.useState(false)
  const { id: userId, hasLoaded: userIdHasLoaded } = useUserId()
  useEffect(() => {
    if (
      !isAuthenticated ||
      !currentUser ||
      !userIdHasLoaded ||
      oneSignalInitialized
    ) {
      return
    }
    Sentry.setUser(currentUser)
    datadogRum.setUser(currentUser)
    if (process.env.INTERCOM_APP_ID) {
      Intercom({
        app_id: 'jxtujjh1',
        user_id: currentUser.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: currentUser.username, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: currentUser.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        created_at: currentUser.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      })
    }
    const initializeOneSignal = async () => {
      await OneSignal.init({
        appId: process.env.ONESIGNAL_APP_ID,
        allowLocalhostAsSecureOrigin: true,
        webhooks: {
          cors: true, // Defaults to false if omitted
        },
      })
      await OneSignal.login(userId)
      await OneSignal.User.addEmail(currentUser.email as string)
      if (!OneSignal.Notifications.permission) {
        await OneSignal.Notifications.requestPermission()
      }
      setOneSignalInitialized(true)
    }
    initializeOneSignal()
  }, [
    currentUser,
    isAuthenticated,
    userIdHasLoaded,
    userId,
    oneSignalInitialized,
  ])

  const org = useOrganization()
  useEffect(() => {
    if (session) {
      session.touch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrgSlug])
  useEffect(() => {
    if (org.isLoaded) {
      dispatch(
        setClerkData({
          permissions: org?.membership?.permissions ?? [],
          isLoaded: org.isLoaded,
        })
      )
    }

    return () => {
      dispatch(clearClerkData())
    }
  }, [org, dispatch])

  const unauthorized = useCallback(
    () =>
      !(
        isAuthenticated &&
        organizationList.some((org) => org.organization.slug === urlOrgSlug)
      ),
    [isAuthenticated, organizationList, urlOrgSlug]
  )
  const setActiveOrg = useCallback(async () => {
    if (activeOrgSlug !== urlOrgSlug) {
      setSwitchOrgInProgress(true)
      const org = organizationList.find(
        (org) => org.organization.slug === urlOrgSlug
      )
      if (org) {
        setActive({ organization: org.organization.id }).finally(() => {
          setSwitchOrgInProgress(false)
        })
      } else {
        setSwitchOrgInProgress(false)
      }
    }
    return true
  }, [activeOrgSlug, urlOrgSlug, organizationList, setActive])
  if (
    isAuthLoading ||
    !isOrgLoaded ||
    !isClerkAuthLoaded ||
    !org.isLoaded ||
    switchOrgInProgress
  ) {
    return null
  }
  if (unauthorized()) {
    return <Redirect to={routes.forbidden()} />
  }
  // When the User is authorized make sure the active org is set
  setActiveOrg()
  return <>{children}</>
}

export default AuthenticatorLayout
