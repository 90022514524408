import { ThemeProvider } from '@mui/material/styles'
import { StyledEngineProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Uploady from '@rpldy/uploady'
import { Provider } from 'react-redux'

import { RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import Sentry from 'src/lib/sentry'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import { AuthProvider, useAuth } from './auth'
import CustomApolloLink from './CustomApolloLink'
import store from './store'
import theme from './theme'
import './i18n/i18n'

import 'src/lib/logger'
import 'src/lib/datadogRum'

import './index.css'

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <AuthProvider>
          <RedwoodApolloProvider
            useAuth={useAuth}
            graphQLClientConfig={{ link: CustomApolloLink }}
          >
            <Uploady>
              <Provider store={store}>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Routes />
                    </LocalizationProvider>
                  </ThemeProvider>
                </StyledEngineProvider>
              </Provider>
            </Uploady>
          </RedwoodApolloProvider>
        </AuthProvider>
      </RedwoodProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
