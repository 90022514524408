import { createSlice } from '@reduxjs/toolkit'

interface State {
  data: string[]
}

const initialState: State = {
  data: [],
}

const duelistColumnsSlice = createSlice({
  name: 'duelistColumns',
  initialState,
  reducers: {
    setDuelistColumnsData: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setDuelistColumnsData } = duelistColumnsSlice.actions

export default duelistColumnsSlice.reducer
